import React, { useState } from "react";
import './index.css';
import { useNavigate } from "react-router-dom";
import wxCode from '../../assets/yanxuan/bottom/wxCode.png';
import shopCode from '../../assets/yanxuan/bottom/shopCode.png';
import gzhCode from '../../assets/yanxuan/bottom/gongzhonghao.png';
import AiAssistant from "../../components/aiAssistant";
const BottomView = (props) => {

  return <div className="BottomViewCon">
    <div className="BottomView">
      <div className="left">
        <div className="lxwm">联系我们</div>
        <div className="gs">公司地址：安国市金融路中央步行街38-3-06-B</div>
        <div className="gs">联系电话：400-897-0909</div>
      </div>
      <div className="right">
        
        <div className="codeName"><img className="codeImg" src={wxCode} /><div className="gs">企业微信</div></div>
        <div className="codeName1"><img className="codeImg" src={gzhCode} /><div className="gs">公众号</div></div>
        <div className="codeName1"><img className="codeImg" src={shopCode} /><div className="gs">商城码</div></div>
        
      </div>
    </div>
    <div className="beian">
    <a className="text-color" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13068302000140" target="_blank">冀公网安备13068302000140号</a>
    <a className="text-color" href="https://beian.miit.gov.cn" target="_blank">冀ICP备2024055534号-5</a>
    <a className="text-color" href="https://dxzhgl.miit.gov.cn" target="_blank">增值电信业务经营许可：冀B2-20240160</a>
    </div>
    <div className="right-color">
    Copyright © 2023-2025 药都严选-药都严选健康产业集团（安国）有限公司版权所有
    </div>
    <AiAssistant/>
  </div>
}

export default BottomView;